import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DrinkData } from "../types";

interface RateModalState {
  opened: boolean;
  drink: DrinkData | null;
}

const initialState: RateModalState = {
  opened: false,
  drink: null,
};

export const ratingModalSlice = createSlice({
  name: "ratingsModal",
  initialState,
  reducers: {
    openRateModal(state: RateModalState, action: PayloadAction<DrinkData>) {
      state.opened = true;
      state.drink = action.payload;
    },
    closeRateModal(state: RateModalState) {
      state.opened = false;
      state.drink = null;
    },
  },
});

export const { openRateModal, closeRateModal } = ratingModalSlice.actions;
