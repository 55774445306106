import { Rating, styled, RatingProps } from "@mui/material";

import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import React from "react";

const StyledRating = styled(Rating)(({ theme }) => ({
  "& .MuiRating-iconFilled, & .MuiRating-iconHover": {
    color: theme.palette.primary.dark,
  },
}));

const TasteRating = (
  props: Omit<RatingProps, "icon" | "emptyIcon"> = {
    readOnly: false,
    precision: 1,
  }
) => {
  return (
    <StyledRating
      icon={<FavoriteIcon fontSize="inherit" />}
      emptyIcon={<FavoriteBorderIcon fontSize="inherit" />}
      {...props}
    />
  );
};

export default React.memo(TasteRating);
