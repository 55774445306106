import { Button, Paper } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import * as serviceWorker from "../serviceWorker";

const SWUpdate = () => {
  const [shown, setShown] = useState(false);
  const [registration, setRegistration] =
    useState<ServiceWorkerRegistration | null>(null);

  const updateSw = useCallback(() => {
    setShown(false);
    registration?.waiting?.postMessage({ type: "SKIP_WAITING" });
  }, [registration, setShown]);

  useEffect(() => {
    serviceWorker.register({
      onUpdate: (registration) => {
        setRegistration(registration);
        setShown(true);
      },
    });
  }, []);

  return shown ? (
    <Paper
      sx={{
        width: "100%",
        borderRadius: 0,
        textAlign: "center",
        backgroundColor: (theme) => theme.palette.info.main,
        fontSize: (theme) => theme.typography.fontSize,
      }}
    >
      <div>Vous utilisez une ancienne version de l'application :</div>
      <Button
        onClick={() => setShown(false)}
        size="small"
        variant="text"
        sx={{ color: "black" }}
      >
        Plus tard
      </Button>
      <Button
        onClick={updateSw}
        size="small"
        variant="text"
        sx={{ color: "white" }}
      >
        Mettre à jour
      </Button>
    </Paper>
  ) : null;
};

export default SWUpdate;
