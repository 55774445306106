import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { drinksApi } from "../api/drinks";
import { DrinkData } from "../types";
import MiniSearch from "minisearch";

export const drinksIndex = new MiniSearch<DrinkData>({
  fields: ["name", "ingredients"],
  idField: "id",
});

const drinksAdapter = createEntityAdapter<DrinkData>({
  selectId: (drink) => drink.id,
});

export const drinksSlice = createSlice({
  name: "drinks",
  initialState: drinksAdapter.getInitialState(),
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      drinksApi.endpoints.getDrinks.matchFulfilled,
      (state, { payload }) => {
        drinksIndex.removeAll();
        drinksIndex.addAll(payload);

        drinksAdapter.setAll(state, payload);
      }
    );

    builder.addMatcher(
      drinksApi.endpoints.getSingleDrink.matchFulfilled,
      (state, { payload }) => {
        drinksAdapter.setOne(state, payload);
      }
    );
  },
});
