import { CircularProgress, Grid, Pagination } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { drinksApi } from "../../api/drinks";
import { ratingsApi } from "../../api/ratings";
import {
  getComparator,
  useDrinksSearchValue,
  useDrinksSorter,
  useDrinksFilters,
  getFilter,
  FilterType,
} from "../../redux/drinks-list";
import { useAppSelector } from "../../redux/hooks";
import { DrinkData, RatingData } from "../../types";
import DrinkListItem from "./DrinkListItem";
import { drinksIndex } from "../../redux/drinks";

const PAGE_SIZE = 20;

const DrinksList = () => {
  // Fetching
  const { isLoading: drinksLoading } = drinksApi.useGetDrinksQuery(null);
  const {
    isLoading: ratingsLoading,
    isError: ratingsErrored,
    isSuccess: ratingsSuccess,
  } = ratingsApi.useGetRatingsQuery(null);

  // Data
  const drinks = useAppSelector((state) => state.drinks.entities);
  const ratings = useAppSelector((state) => state.ratings.entities);

  // Search

  const searchString = useDrinksSearchValue();

  const drinksArray = useMemo(() => {
    if (searchString.length === 0) {
      return Object.values(drinks).map((drink) => drink!);
    } else {
      return drinksIndex
        .search(searchString, {
          prefix: true,
        })
        .map((hit) => drinks[hit.id]!);
    }
  }, [searchString, drinks]);

  // Filtering
  const filters = useDrinksFilters();

  const combinedFilter = useMemo(() => {
    let comp = (drink: DrinkData, rating?: RatingData) => true;
    for (const filterName of filters) {
      const filter = getFilter(filterName as FilterType);

      const base = comp;
      comp = (drink: DrinkData, rating?: RatingData) =>
        base(drink, rating) && filter(drink, rating);
    }
    return comp;
  }, [filters]);

  const filteredDrinks = useMemo(
    () =>
      drinksArray.filter((drink) => combinedFilter(drink, ratings[drink.id])),
    [drinksArray, combinedFilter, ratings]
  );

  // Sorting

  const sortType = useDrinksSorter();

  const sortedDrinks = useMemo(() => {
    const sortedArray = [...filteredDrinks];
    sortedArray.sort(getComparator(sortType.type));
    return sortedArray;
  }, [filteredDrinks, sortType.type]);

  // Paging

  let maxPage = Math.ceil(sortedDrinks.length / PAGE_SIZE);
  if (maxPage === 0) {
    maxPage = 1;
  }
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    if (currentPage > maxPage) {
      setCurrentPage(0);
    }
  }, [sortedDrinks.length, maxPage, currentPage]);

  const paginatedDrinks = useMemo(
    () =>
      sortedDrinks.reduce(
        (prev, curr) => {
          if (prev[prev.length - 1].length >= PAGE_SIZE) {
            prev.push([]);
          }
          prev[prev.length - 1].push(curr);
          return prev;
        },
        [[]] as DrinkData[][]
      ),
    [sortedDrinks]
  );

  // Rendering

  if (drinksLoading || (ratingsLoading && !ratingsErrored)) {
    return (
      <Grid
        container
        item
        direction="column"
        sx={{ minHeight: "60vh" }}
        justifyContent="center"
      >
        <Grid container justifyContent="center" direction="row">
          <Grid item>
            <CircularProgress color="secondary" />
          </Grid>
        </Grid>
      </Grid>
    );
  }

  const pagination = (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <Pagination
        count={paginatedDrinks.length}
        page={currentPage + 1}
        onChange={(_, page) => setCurrentPage(page - 1)}
      />
    </div>
  );

  return (
    <>
      {pagination}
      {paginatedDrinks[Math.min(currentPage, maxPage - 1)].map((drink, i) => {
        return (
          <DrinkListItem
            key={i}
            drink={drink}
            rating={ratings[drink.id]}
            canRate={ratingsSuccess}
          />
        );
      })}
      {pagination}
    </>
  );
};

export default DrinksList;
