import { createTheme } from "@mui/material";
import { LinkProps, Link } from "react-router-dom";
import React from "react";

const LinkBehavior = React.forwardRef<
  any,
  Omit<LinkProps, "to"> & { href: LinkProps["to"] }
>((props, ref) => {
  const { href, ...other } = props;
  return <Link ref={ref} to={href} {...other} />;
});

export const theme = createTheme({
  palette: {
    background: {
      default: "rgb(161, 112, 145)",
    },
    primary: {
      main: "#8A4D76",
      contrastText: "#FFFFFF",
    },
    secondary: {
      main: "#9c0973",
    },
  },
  components: {
    MuiLink: {
      defaultProps: {
        // @ts-ignore
        component: LinkBehavior,
      },
    },
    MuiButtonBase: {
      defaultProps: {
        LinkComponent: LinkBehavior,
      },
    },
  },
});
