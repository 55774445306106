import { Grid, IconButton } from "@mui/material";
import AlphaIcon from "@mui/icons-material/SortByAlpha";
import FavoriteIcon from "@mui/icons-material/Favorite";
import DrinkIcon from "@mui/icons-material/LocalBar";
import { useEffect, useState } from "react";
import { useAppDispatch } from "../../redux/hooks";
import { updateSorter } from "../../redux/drinks-list";

const sortItems = [
  {
    label: "alpha",
    icon: AlphaIcon,
  },
  {
    label: "taste",
    icon: FavoriteIcon,
  },
  {
    label: "alcohol",
    icon: DrinkIcon,
  },
] as const;

const SortBar = () => {
  const [selected, setSelected] =
    useState<typeof sortItems[number]["label"]>("alpha");

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(updateSorter(selected));
  }, [dispatch, selected]);

  return (
    <Grid container direction="row" justifyContent="space-around" spacing={2}>
      {sortItems.map(({ label, icon: Icon }) => {
        return (
          <Grid key={label} item>
            <IconButton
              color={label === selected ? "secondary" : "default"}
              onClick={() => setSelected(label)}
            >
              <Icon />
            </IconButton>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default SortBar;
