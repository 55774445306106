import React from "react";
import ReactDOM from "react-dom";
import { store } from "./redux/store";
import { Provider } from "react-redux";
import Navbar from "./components/Navbar";
import { theme } from "./theme";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Drinks from "./pages/Drinks";
import Roulette from "./pages/Roulette";
import BrandBar from "./components/BrandBar";
import RatingModal from "./components/RatingModal";
import SWUpdate from "./components/SWUpdate";

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <BrowserRouter>
          <BrandBar />
          <SWUpdate />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/drinks" element={<Drinks />} />
            <Route path="/roulette" element={<Roulette />} />
          </Routes>
          <RatingModal />
          <Navbar />
        </BrowserRouter>
      </ThemeProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
