import { api } from ".";
import { DrinkData } from "../types";

export const drinksApi = api.injectEndpoints({
  endpoints: (build) => ({
    getDrinks: build.query<DrinkData[], null>({
      query: () => "/api/drinks",
    }),
    getSingleDrink: build.query<DrinkData, string>({
      query: (id) => `/api/drinks/${id}`,
    }),
  }),
});
