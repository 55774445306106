import { AppBar, Popover, Typography, IconButton, Box } from "@mui/material";
import InfoIcon from "@mui/icons-material/Help";
import { useState } from "react";

const BrandBar = () => {
  const [anchor, setAnchor] = useState<HTMLButtonElement | null>(null);

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchor(event.currentTarget);
  };

  const handleClose = () => setAnchor(null);

  return (
    <AppBar position="static">
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginX: (theme) => theme.spacing(1),
        }}
      >
        <Typography
          variant="h6"
          component="div"
          sx={{ padding: (theme) => theme.spacing(1) }}
          textAlign="center"
        >
          DTèr pour un shot
        </Typography>
        <IconButton onClick={handleOpen}>
          <InfoIcon htmlColor="white" />
        </IconButton>
      </Box>
      <Popover open={Boolean(anchor)} anchorEl={anchor} onClose={handleClose}>
        <Typography
          variant="body2"
          component="div"
          sx={{ padding: (theme) => theme.spacing(1) }}
        >
          En cas de shots manquants, d'erreurs ou de tout autres problèmes,
          contactez{" "}
          <a
            href="https://www.facebook.com/florent.hugouvieux"
            target="_blank"
            rel="noreferrer"
          >
            Florent
          </a>{" "}
          sur Messenger.
        </Typography>
      </Popover>
    </AppBar>
  );
};

export default BrandBar;
