import { Rating, styled, RatingProps } from "@mui/material";

import DrinkIcon from "@mui/icons-material/LocalBar";
import DrinkBorderIcon from "@mui/icons-material/LocalBarOutlined";
import React from "react";

const StyledRating = styled(Rating)({
  "& .MuiRating-iconFilled, & .MuiRating-iconHover": {
    color: "#10995f",
  },
});

const AlcoholRating = (
  props: Omit<RatingProps, "icon" | "emptyIcon"> = {
    readOnly: false,
    precision: 1,
  }
) => {
  return (
    <StyledRating
      icon={<DrinkIcon fontSize="inherit" />}
      emptyIcon={<DrinkBorderIcon fontSize="inherit" />}
      {...props}
    />
  );
};

export default React.memo(AlcoholRating);
