import { Grid, IconButton } from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useCallback } from "react";
import { useAppDispatch } from "../../redux/hooks";
import {
  useDrinksFilters,
  updateFilters,
  FilterType,
} from "../../redux/drinks-list";

const filterItems = [
  {
    label: "starred",
    icons: [StarIcon],
    steps: ["starred"] as FilterType[],
  },
  {
    label: "blacklist",
    icons: [VisibilityIcon, VisibilityOffIcon],
    steps: ["whitelisted", "blacklisted"] as FilterType[],
  },
] as const;

function findIndex(filters: string[], steps: string[]): number {
  const matchingFilters = filters.filter((f) => steps.includes(f));
  if (matchingFilters.length === 0) {
    return -1;
  }
  return steps.indexOf(matchingFilters[0]);
}

function findColor(
  filters: string[],
  steps: string[]
): "secondary" | "default" {
  return findIndex(filters, steps) >= 0 ? "secondary" : "default";
}

function findIcon(
  filters: string[],
  steps: string[],
  icons: typeof filterItems[number]["icons"]
): typeof filterItems[number]["icons"][number] {
  const index = findIndex(filters, steps);
  if (index === -1) {
    return icons[0];
  }
  return icons[index];
}

const FilterBar = () => {
  const dispatch = useAppDispatch();
  const filters = useDrinksFilters();

  const toggleFilter = useCallback(
    (steps: FilterType[]) => {
      const matchingFilters = filters.filter((f) => steps.includes(f));
      if (matchingFilters.length === 0) {
        dispatch(updateFilters(filters.concat(steps[0])));
      } else {
        const stepIndex = steps.indexOf(matchingFilters[0]);
        let newFilters = filters.filter((f) => f !== matchingFilters[0]);
        if (stepIndex < steps.length - 1) {
          newFilters = newFilters.concat(steps[stepIndex + 1]);
        }
        dispatch(updateFilters(newFilters));
      }
    },
    [filters, dispatch]
  );

  return (
    <Grid container direction="row" justifyContent="space-around" spacing={2}>
      {filterItems.map(({ label, icons, steps }) => {
        const Icon = findIcon(filters, steps, icons);
        return (
          <Grid key={label} item>
            <IconButton
              color={findColor(filters, steps)}
              onClick={() => toggleFilter(steps)}
            >
              <Icon />
            </IconButton>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default FilterBar;
