import { Grid, Paper, Typography, Chip, Box } from "@mui/material";
import { useCallback, useMemo } from "react";
import FavoriteIcon from "@mui/icons-material/Favorite";
import DrinkIcon from "@mui/icons-material/LocalBar";
import React from "react";
import { useAppDispatch } from "../../redux/hooks";
import { openRateModal } from "../../redux/ratings-modal";
import TasteRating from "../ratings/TasteRating";
import AlcoholRating from "../ratings/AlcoholRating";
import Starred from "../ratings/Starred";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { DrinkData, RatingData } from "../../types";
import { ratingsApi } from "../../api/ratings";

const numberFormat = Intl.NumberFormat("fr-FR", { maximumFractionDigits: 1 });

function defined(v: boolean | null | undefined): v is boolean {
  return v !== null && v !== undefined;
}

const DrinkListItem = (props: {
  drink: DrinkData;
  rating?: RatingData | undefined;
  canRate?: boolean;
  fixedHeight?: boolean;
}) => {
  const { drink, rating, canRate = false, fixedHeight = false } = props;
  const { tasteRating, alcoholRating } = drink;
  const dispatch = useAppDispatch();

  const onVote = useCallback(
    () => dispatch(openRateModal(drink)),
    [dispatch, drink]
  );

  const [updatePreference] = ratingsApi.useUpdatePreferenceMutation();
  const toggleFavorite = useCallback(() => {
    updatePreference({
      drinkId: drink.id,
      starred: rating && defined(rating.starred) ? !rating.starred : true,
      blacklisted:
        rating && defined(rating.blacklisted) ? rating.blacklisted : false,
    });
  }, [rating, drink.id, updatePreference]);
  const toggleBlacklist = useCallback(() => {
    updatePreference({
      drinkId: drink.id,
      starred: rating && defined(rating.starred) ? rating.starred : false,
      blacklisted:
        rating && defined(rating.blacklisted) ? !rating.blacklisted : true,
    });
  }, [rating, drink.id, updatePreference]);

  const memoizedRatings = useMemo(
    () => (
      <>
        <Grid item>
          <TasteRating precision={0.1} value={tasteRating} readOnly={true} />
        </Grid>
        <Grid item>
          <AlcoholRating precision={0.5} value={alcoholRating} readOnly />
        </Grid>
      </>
    ),
    [tasteRating, alcoholRating]
  );

  return (
    <Paper
      elevation={1}
      sx={{
        marginY: "1rem",
        padding: "0.5rem",
        height: fixedHeight ? "9rem" : undefined,
      }}
    >
      <Grid container direction="column">
        <Grid
          item
          container
          justifyContent="space-between"
          direction="row"
          columnSpacing={4}
        >
          <Grid item container direction="column" xs={8}>
            <Grid item>
              <Typography variant="body1" component="div">
                {drink.name}
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                variant="caption"
                component="div"
                sx={{ color: "#666666" }}
              >
                {drink.ingredients.join(", ")}
              </Typography>
            </Grid>
            {rating &&
            rating.tasteRating != null &&
            rating.alcoholRating != null ? (
              <>
                <Grid item>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography variant="subtitle2" component="div">
                      Mon avis :
                    </Typography>
                    <FavoriteIcon
                      sx={{ color: (theme) => theme.palette.primary.dark }}
                    />
                    <Typography variant="subtitle2" component="div">
                      x{numberFormat.format(rating.tasteRating)}
                    </Typography>
                    <DrinkIcon sx={{ color: "#10995f" }} />
                    <Typography variant="subtitle2" component="div">
                      x{numberFormat.format(rating.alcoholRating)}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item>
                  <Chip
                    label="Modifier mon avis"
                    size="small"
                    onClick={onVote}
                  />
                </Grid>
              </>
            ) : canRate ? (
              <Grid item>
                <Chip
                  label="Je donne mon avis →"
                  size="small"
                  color="secondary"
                  onClick={onVote}
                />
              </Grid>
            ) : null}
          </Grid>
          <Grid
            item
            container
            direction="column"
            alignContent="flex-end"
            xs={2}
          >
            {memoizedRatings}
            <Grid item>
              <Typography variant="caption" component="div" textAlign="right">
                {drink.votesCount} vote{drink.votesCount >= 2 ? "s" : ""}
              </Typography>
            </Grid>
            {canRate && (
              <Grid item>
                <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                  {rating && rating.blacklisted ? (
                    <VisibilityOffIcon onClick={toggleBlacklist} />
                  ) : (
                    <VisibilityIcon onClick={toggleBlacklist} />
                  )}
                  {rating && rating.starred ? (
                    <Starred onClick={toggleFavorite} />
                  ) : (
                    <StarBorderIcon onClick={toggleFavorite} />
                  )}
                </Box>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default React.memo(DrinkListItem);
