import { api } from ".";
import { RatingData } from "../types";

export const ratingsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getRatings: build.query<RatingData[], null>({
      query: () => ({
        url: "/api/ratings",
      }),
    }),
    updateRating: build.mutation<
      RatingData,
      Pick<RatingData, "drinkId" | "alcoholRating" | "tasteRating">
    >({
      query: (rating) => ({
        url: "/api/ratings",
        method: "POST",
        body: rating,
      }),
    }),
    updatePreference: build.mutation<
      RatingData,
      Pick<RatingData, "drinkId" | "starred" | "blacklisted">
    >({
      query: (preference) => ({
        url: "/api/preferences",
        method: "POST",
        body: preference,
      }),
    }),
  }),
});
