import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DrinkData, RatingData } from "../types";
import { useAppSelector } from "./hooks";

export interface DrinksComparator {
  (drink1: DrinkData, drink2: DrinkData): number;
}

export const comparators = <const>{
  alpha: (drink1: DrinkData, drink2: DrinkData) =>
    drink1.name.localeCompare(drink2.name),
  taste: (drink1: DrinkData, drink2: DrinkData) => {
    const diff = drink2.tasteRating - drink1.tasteRating;
    return diff === 0 ? comparators.alpha(drink1, drink2) : diff;
  },
  alcohol: (drink1: DrinkData, drink2: DrinkData) => {
    const diff = drink2.alcoholRating - drink1.alcoholRating;
    return diff === 0 ? comparators.alpha(drink1, drink2) : diff;
  },
};

export const filters = <const>{
  starred: (_drink: DrinkData, rating?: RatingData) =>
    rating !== undefined && rating.starred,
  whitelisted: (_drink: DrinkData, rating?: RatingData) =>
    rating === undefined || !rating.blacklisted,
  blacklisted: (_drink: DrinkData, rating?: RatingData) =>
    rating !== undefined && rating.blacklisted,
};

type SortType = keyof typeof comparators;
export type FilterType = keyof typeof filters;

const initialState = {
  sorter: {
    type: "alpha",
  },
  filter: {
    search: "",
    typedFilters: ["whitelisted"] as FilterType[],
  },
};

const drinksListSlice = createSlice({
  initialState: initialState,
  name: "drinksList",
  reducers: {
    updateSorter(state, action: PayloadAction<SortType>) {
      state.sorter = {
        type: action.payload,
      };
    },
    updateSearch(state, action: PayloadAction<string>) {
      state.filter = {
        search: action.payload,
        typedFilters: state.filter.typedFilters,
      };
    },
    updateFilters(state, action: PayloadAction<FilterType[]>) {
      state.filter = {
        search: state.filter.search,
        typedFilters: action.payload,
      };
    },
  },
});

export const { updateSorter, updateSearch, updateFilters } =
  drinksListSlice.actions;
export default drinksListSlice;
export const useDrinksSorter = () =>
  useAppSelector((state) => state.drinksList.sorter);
export const useDrinksSearchValue = () =>
  useAppSelector((state) => state.drinksList.filter.search);
export const useDrinksFilters = () =>
  useAppSelector((state) => state.drinksList.filter.typedFilters);
export const getComparator = (type: string) =>
  comparators[type as SortType] || comparators["alpha"];
export const getFilter = (type: FilterType) => filters[type];
