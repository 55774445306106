import {
  Button,
  CircularProgress,
  Container,
  Grid,
  Typography,
  Paper,
} from "@mui/material";
import { useCallback, useEffect, useState, useMemo } from "react";
import { drinksApi } from "../api/drinks";
import { ratingsApi } from "../api/ratings";
import DrinkListItem from "../components/drinks-list/DrinkListItem";
import { DrinkData, RatingData } from "../types";
import FilterBar from "../components/drinks-list/FilterBar";
import { useAppSelector } from "../redux/hooks";
import { useDrinksFilters, getFilter, FilterType } from "../redux/drinks-list";

function defined<T>(v: T | undefined): v is T {
  return v !== undefined;
}

const Roulette = () => {
  const { data } = drinksApi.useGetDrinksQuery(null);
  ratingsApi.useGetRatingsQuery(null);

  const [iterableDrinks, setIterableDrinks] = useState<DrinkData[]>([]);
  const [drinkId, setDrinkId] = useState<string | null>(null);
  const [running, setRunning] = useState(true);
  const drinks = useAppSelector((state) => state.drinks.entities);
  const ratings = useAppSelector((state) => state.ratings.entities);

  // Filtering
  const filters = useDrinksFilters();

  const combinedFilter = useMemo(() => {
    let comp = (drink: DrinkData, rating?: RatingData) => true;
    for (const filterName of filters) {
      const filter = getFilter(filterName as FilterType);

      const base = comp;
      comp = (drink: DrinkData, rating?: RatingData) =>
        base(drink, rating) && filter(drink, rating);
    }
    return comp;
  }, [filters]);

  useEffect(() => {
    const allDrinks = Object.values(drinks).filter(defined);
    if (allDrinks.length) {
      setIterableDrinks(
        allDrinks.filter((d) => combinedFilter(d, ratings[d.id]))
      );
    }
  }, [drinks, combinedFilter, ratings]);

  useEffect(() => {
    if (
      drinkId !== null &&
      !iterableDrinks.map((d) => d.id).includes(drinkId)
    ) {
      setDrinkId(null);
    }
  }, [iterableDrinks, drinkId]);

  const pickOne = useCallback(() => {
    if (iterableDrinks.length === 0) return null;
    const index = Math.random() * iterableDrinks.length;
    const drink = iterableDrinks[Math.floor(index)];
    setDrinkId(drink.id);
  }, [iterableDrinks]);

  const switchRunning = useCallback(() => {
    setRunning(!running);
  }, [running, setRunning]);

  useEffect(() => {
    if (data && running) {
      const id = setInterval(() => {
        pickOne();
      }, 10);
      return () => clearInterval(id);
    }
  }, [data, pickOne, running]);

  const drink = useMemo(
    () => (drinkId ? drinks[drinkId] : null),
    [drinks, drinkId]
  );

  if (!data) {
    return (
      <Grid
        container
        item
        direction="column"
        sx={{ minHeight: "100vh" }}
        justifyContent="center"
      >
        <Grid container justifyContent="center" direction="row">
          <Grid item>
            <CircularProgress color="secondary" />
          </Grid>
        </Grid>
      </Grid>
    );
  }

  return (
    <Container>
      <Grid
        container
        item
        direction="column"
        sx={{ minHeight: "85vh" }}
        justifyContent="center"
      >
        <Grid container justifyContent="center" direction="row">
          <Grid item container direction="column">
            <Grid item>
              <Typography
                variant="h5"
                component="h2"
                sx={{ color: "white" }}
                textAlign="center"
              >
                Roulette des shots
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                variant="subtitle2"
                component="div"
                sx={{ color: "white" }}
              >
                Filtrer par :
              </Typography>
            </Grid>
            <Grid item>
              <FilterBar />
            </Grid>
            <Grid item>
              {drink ? (
                <DrinkListItem
                  drink={drink}
                  fixedHeight={true}
                  canRate={!running}
                  rating={ratings[drink.id]}
                />
              ) : (
                <Paper
                  elevation={1}
                  sx={{ height: "9rem", marginY: "1rem", padding: "0.5rem" }}
                >
                  <Typography
                    variant="subtitle2"
                    component="div"
                    textAlign="center"
                  >
                    Aucune correspondance pour les filtres donnés
                  </Typography>
                </Paper>
              )}
            </Grid>
            <Grid item container direction="row" justifyContent="space-around">
              <Grid item>
                <Button variant="contained" onClick={switchRunning}>
                  {running ? "Arrêter" : "Relancer"}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Roulette;
