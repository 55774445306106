import { styled } from "@mui/material";

import StarIcon from "@mui/icons-material/Star";
import React from "react";

const StyledStar = styled(StarIcon)({
  "&": {
    fill: "#a17091",
  },
});

export default React.memo(StyledStar);
