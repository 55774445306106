import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { ratingsApi } from "../api/ratings";
import { RatingData } from "../types";

export const ratingsAdapter = createEntityAdapter<RatingData>({
  selectId: (rating) => rating.drinkId,
});

export const ratingsSlice = createSlice({
  name: "ratings",
  initialState: ratingsAdapter.getInitialState(),
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      ratingsApi.endpoints.getRatings.matchFulfilled,
      (state, { payload }) => {
        ratingsAdapter.setAll(state, payload);
      }
    );
    builder.addMatcher(
      ratingsApi.endpoints.updateRating.matchFulfilled,
      (state, { payload }) => {
        ratingsAdapter.setOne(state, payload);
      }
    );
    builder.addMatcher(
      ratingsApi.endpoints.updatePreference.matchFulfilled,
      (state, { payload }) => ratingsAdapter.setOne(state, payload)
    );
  },
});
