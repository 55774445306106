import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import { api } from "../api";
import drinksListSlice from "./drinks-list";
import { ratingModalSlice } from "./ratings-modal";
import { ratingsSlice } from "./ratings";
import { drinksSlice } from "./drinks";

export const store = configureStore({
  reducer: {
    [api.reducerPath]: api.reducer,
    [drinksListSlice.name]: drinksListSlice.reducer,
    [ratingModalSlice.name]: ratingModalSlice.reducer,
    [ratingsSlice.name]: ratingsSlice.reducer,
    [drinksSlice.name]: drinksSlice.reducer,
  },
  middleware: (getDefaultMiddlewares) =>
    getDefaultMiddlewares().concat(api.middleware),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
