import DrinkSearchField from "../components/DrinkSearchField";
import { Container, Stack, Typography, Link } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useCallback } from "react";
import { drinksApi } from "../api/drinks";

const Home = () => {
  const prefetchDrinks = drinksApi.usePrefetch("getDrinks");
  const navigate = useNavigate();
  const onSubmit = useCallback(
    (value: string) => {
      const searchParams = new URLSearchParams({
        query: value,
      });
      navigate(`/drinks?${searchParams.toString()}`);
    },
    [navigate]
  );
  const onChange = useCallback(() => prefetchDrinks(null), [prefetchDrinks]);

  return (
    <Container sx={{ height: "100%", minHeight: "100vh" }}>
      <Stack
        spacing={1}
        justifyContent="center"
        sx={{ height: "100%", minHeight: "85vh" }}
      >
        <Typography
          textAlign="center"
          variant="h5"
          component="h2"
          sx={{ fontWeight: "bold", color: "white" }}
        >
          Recherchez un verre ou un ingrédient
        </Typography>
        <DrinkSearchField
          showRightIcon={true}
          fullWidth={true}
          onSubmit={onSubmit}
          onChange={onChange}
        />
        <Typography textAlign="right" component="div" variant="caption">
          <Link href="/drinks" color="inherit">
            Accéder à la liste complète
          </Link>
        </Typography>
      </Stack>
    </Container>
  );
};

export default Home;
