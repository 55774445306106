import { BottomNavigation, BottomNavigationAction, Paper } from "@mui/material";
import DiceIcon from "@mui/icons-material/Casino";
import DrinkIcon from "@mui/icons-material/LocalBar";
import { useCallback, useEffect, useState } from "react";
import { useMatch, useNavigate } from "react-router-dom";

const Navbar = () => {
  const drinksMatched = useMatch("/drinks");
  const rouletteMatched = useMatch("/roulette");
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState(
    drinksMatched ? 0 : rouletteMatched ? 1 : -1
  );

  const onChange = useCallback(
    (_event: React.SyntheticEvent<Element, Event>, value: number) => {
      if (value !== selectedTab) {
        if (value === 0) {
          navigate("/drinks");
        } else if (value === 1) {
          navigate("/roulette");
        }
      }
      setSelectedTab(value);
    },
    [setSelectedTab, navigate, selectedTab]
  );

  useEffect(() => {
    setSelectedTab(drinksMatched ? 0 : rouletteMatched ? 1 : -1);
  }, [drinksMatched, rouletteMatched]);

  return (
    <Paper
      sx={{ position: "fixed", bottom: 0, left: 0, right: 0, zIndex: 5 }}
      elevation={3}
    >
      <BottomNavigation value={selectedTab} onChange={onChange}>
        <BottomNavigationAction
          label="Liste des boissons"
          icon={<DrinkIcon />}
        />
        <BottomNavigationAction label="Roulette" icon={<DiceIcon />} />
      </BottomNavigation>
    </Paper>
  );
};

export default Navbar;
