import { IconButton, InputAdornment, TextField } from "@mui/material";
import IconArrow from "@mui/icons-material/ArrowForward";
import React, { useCallback, useState } from "react";

const DrinkSearchField = (
  props: {
    showRightIcon: boolean;
    fullWidth?: boolean;
    onChange?: (value: string) => void;
    onSubmit?: (value: string) => void;
    defaultValue?: string;
  } = { showRightIcon: true, fullWidth: false, defaultValue: "" }
) => {
  const { showRightIcon, fullWidth } = props;
  const [value, setValue] = useState(props.defaultValue || "");

  const onChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setValue(event.target.value);
      if (props.onChange) {
        props.onChange(event.target.value);
      }
    },
    [props]
  );

  const submit = useCallback(() => {
    if (props.onSubmit) {
      props.onSubmit(value);
    }
  }, [props, value]);

  const onSubmit = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      submit();
    },
    [submit]
  );

  return (
    <form onSubmit={onSubmit}>
      <TextField
        sx={{ backgroundColor: "white" }}
        variant="filled"
        color="secondary"
        hiddenLabel={true}
        size="small"
        fullWidth={fullWidth}
        onChange={onChange}
        value={value}
        placeholder="Ex: kamikaze, vokda ..."
        InputProps={{
          endAdornment: showRightIcon ? (
            <InputAdornment position="end">
              <IconButton onClick={submit} aria-label="Valider">
                <IconArrow />
              </IconButton>
            </InputAdornment>
          ) : undefined,
        }}
      />
    </form>
  );
};

export default DrinkSearchField;
