import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import getFingerprint from "get-browser-fingerprint";

let fingerprint: string = "";
if (localStorage.getItem("dt-fingerprint") !== null) {
  fingerprint = localStorage.getItem("dt-fingerprint")!;
} else {
  fingerprint = getFingerprint(true);
  localStorage.setItem("dt-fingerprint", fingerprint);
}

export const api = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    prepareHeaders: (headers) => {
      headers.set("Authorization", fingerprint);
      return headers;
    },
  }),
  endpoints: () => ({}),
});
