import { useAppDispatch, useAppSelector } from "../redux/hooks";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { closeRateModal } from "../redux/ratings-modal";
import TasteRating from "./ratings/TasteRating";
import AlcoholRating from "./ratings/AlcoholRating";
import { ratingsApi } from "../api/ratings";
import { drinksApi } from "../api/drinks";

const RateModal = () => {
  const modalState = useAppSelector((state) => state.ratingsModal);
  const [updateRating, { isLoading }] = ratingsApi.useUpdateRatingMutation();
  const [refetchDrinkData] = drinksApi.useLazyGetSingleDrinkQuery();

  const ratings = useAppSelector((state) => state.ratings.entities);

  const [alcohol, setAlcohol] = useState(0);
  const [taste, setTaste] = useState(0);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (modalState.opened && modalState.drink) {
      const rating = ratings[modalState.drink.id];
      setTaste(rating?.tasteRating || 0);
      setAlcohol(rating?.alcoholRating || 0);
    }
  }, [modalState, setAlcohol, setTaste, ratings]);

  const onClose = useCallback(() => {
    setAlcohol(0);
    setTaste(0);
    dispatch(closeRateModal());
  }, [dispatch]);

  const onSubmit = useCallback(() => {
    if (modalState.drink) {
      updateRating({
        drinkId: modalState.drink.id,
        alcoholRating: alcohol,
        tasteRating: taste,
      })
        .then(() => modalState.drink && refetchDrinkData(modalState.drink.id))
        .then(() => onClose());
    }
  }, [updateRating, onClose, modalState, alcohol, taste, refetchDrinkData]);

  const canSubmit = useMemo(() => alcohol > 0 && taste > 0, [alcohol, taste]);

  return (
    <Dialog open={modalState.opened} onClose={onClose}>
      <DialogTitle>
        Notez le verre
        <Typography sx={{ fontWeight: "bold" }}>
          {modalState.drink?.name}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Stack>
          <Typography>Goût</Typography>
          <TasteRating
            value={taste}
            onChange={(_event, value) => setTaste(value || 0)}
            size="large"
            precision={0.5}
          />
          <Typography>Alcool</Typography>
          <AlcoholRating
            value={alcohol}
            onChange={(_event, value) => setAlcohol(value || 0)}
            size="large"
            precision={0.5}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Annuler</Button>
        <Button onClick={onSubmit} disabled={!canSubmit || isLoading}>
          {isLoading ? "Envoie ..." : "Valider mon vote"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RateModal;
