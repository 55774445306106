import { Container, Typography } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import DrinksList from "../components/drinks-list/DrinksList";
import SortBar from "../components/drinks-list/SortBar";
import FilterBar from "../components/drinks-list/FilterBar";
import DrinkSearchField from "../components/DrinkSearchField";
import debounce from "lodash.debounce";
import { useAppDispatch } from "../redux/hooks";
import { updateSearch } from "../redux/drinks-list";

const Drinks = () => {
  const [params, setParams] = useSearchParams();
  const searchString = params.get("query") || "";
  const [startValue] = useState(searchString);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(updateSearch(startValue));
  }, [dispatch, startValue]);

  const onChange = debounce(
    useCallback(
      (value: string) => {
        setParams(
          {
            query: value,
          },
          {
            replace: true,
          }
        );
        dispatch(updateSearch(value));
      },
      [setParams, dispatch]
    ),
    200
  );

  return (
    <Container
      sx={{
        height: "100%",
        minHeight: "100vh",
        marginTop: "1rem",
        marginBottom: "5rem",
      }}
    >
      <Typography
        variant="h5"
        component="h2"
        sx={{ color: "white", marginBottom: (theme) => theme.spacing(2) }}
      >
        Liste des boissons
      </Typography>
      <DrinkSearchField
        showRightIcon={false}
        fullWidth={true}
        defaultValue={startValue}
        onChange={onChange}
      />
      <Typography
        variant="subtitle2"
        component="div"
        sx={{ color: "white", marginTop: (theme) => theme.spacing(1) }}
      >
        Trier par :
      </Typography>
      <SortBar />
      <Typography
        variant="subtitle2"
        component="div"
        sx={{ color: "white", marginTop: (theme) => theme.spacing(1) }}
      >
        Filtrer par :
      </Typography>
      <FilterBar />
      <DrinksList />
    </Container>
  );
};

export default Drinks;
